import { useState, useEffect } from "react";
import { useAuth, useAxios } from "../hooks/auth";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Alert,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "../../node_modules/@mui/material/index";
import StageNamePulldown from "../components/misc/StageNamePulldown";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../constants/sideBarwidth";
import { dashboardGetData, columnFilterData, patientsPaginationGetData, getDashboardData, getDmeDashboardData } from "../axios/api";

import {
  notificationToastError,
  notificationToastSuccess,
} from "../utils/notification";
import { setSnackbarMessage } from "../store/slices/snackBarSlice";
import ScheduleStudyDialog from "./misc/ScheduleStudyDialog";
import TreatmentScheduleStudyDialog from "./misc/TreatmentScheduleStudyDialog";
import RescheduleStudyDialog from "./misc/RescheduleStudyDialog";
import RescheduleCancelDialog from "./misc/RescheduleCancelDialog";
import InterpretationAvailableDialog from "./misc/InterpretationAvailableDialog";
import VerifyInsuranceDialog from "./misc/VerifyInsuranceDialog";
import DmeVerifyInsuranceDialog from "./misc/DmeVerifyInsuranceDialog";
import ConsentFormsDialog from "./misc/ConsentFormsDialog";
import WaitingScheduleDialog from "./misc/WaitingScheduleDialog";
import OrderInfoDialog from "./misc/OrderInfoDialog";
import GetAuthorizationDialog from "./misc/GetAuthorizationDialog";
import UpdateStudyInfoDialog from "./misc/UpdateStudyInfoDialog";
import UploadInterpretationDialog from "./misc/UploadInterpretationDialog";
import DiscussResultsDialog from "./misc/DiscussResultsDialog";
import ResultsFollowupDialog from "./misc/ResultsFollowupDialog";
import TreatmentAppointmentDialog from "./misc/TreatmentAppointmentDialog";
import UploadComplianceDialog from "./misc/UploadComplianceDialog";
import { updateSelectedPatient } from "../store/slices/selectedPatientSlice";
import ScoreStudyDialog from "./misc/ScoreStudyDialog";
import CompleteBillingDialog from "./misc/CompleteBillingDialog";
import useIsUserId from "../hooks/userIdInfo";
import useIsGroupId from "../hooks/groupIdInfo";
import moment from "moment";
import { schedulingPostData } from "../axios/api";
import { useDispatch } from "react-redux";
import { updatedashboardData } from "../store/slices/dashboardDataSlice";
import useIsPrevileges from "../hooks/useRoleAndPrivileges";
import { roles } from "../constants/tabs";
import ReschedulePopup from "./misc/ReschedulePopup";
import DmeConsentFormsDialog from "./misc/DmeConsentFormsDialog";
import DmeUpdateStudyInfoDialog from "./misc/DmeUpdateStudyInfoDialog";

export default function Dashboard() {
  //Main function

  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:800px)");
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const [formState, setFormState] = useState({});
  const { role } = useIsPrevileges();
  const { isAllPatients } = useIsPrevileges();
  const { isReviewResults } = useIsPrevileges();
  const { isDiscussResults } = useIsPrevileges();
  const isPhysician = role === roles?.PHYSICIAN;
  const [columnState, setColumnState] = useState({})
  
  const columnObj = {
    "patientNum" : "patientId",
    "patientName" : "patientName",
    "dob" : "dob",
    "name" : "physician",
    "groupName" : "patGroup",
    "locationStudy" : "facility",
    "orderDate" : "orderDate",
    "prefLocation" : "prefLocation",
    "contacAttemptOrder" : "contactAttempt",
    "planNameP" : "insurance",
    "date" : "studyDate",
    "studyType" : "studyType",
    "stage" : "stageStatus",
    "Actions":"Actions"
  }

  const [
    viewTreatmentScheduleStudyState,
    setViewTreatmentScheduleStudyDialogState,
  ] = useState(undefined);

  const userId = useIsUserId();
  const groupId = useIsGroupId();

  const [
    viewTreatmentAppointmentState,
    setViewTreatmentAppointmentDialogState,
  ] = useState(undefined);

  const [viewUploadComplianceState, setViewUploadComplianceDialogState] =
    useState(undefined);

  const [viewScheduleStudyState, setViewScheduleStudyDialogState] =
    useState(undefined);
  const [
    viewInterpretationAvailableState,
    setViewInterpretationAvailableDialogState,
  ] = useState(undefined);
  const [
    viewInterpretationAvailableDiscussState,
    setViewInterpretationAvailableDiscussDialogState,
  ] = useState(undefined);
  const [viewRescheduleStudyState, setViewRescheduleStudyDialogState] =
    useState(undefined);
    const [viewRescheduleCancelState, setViewRescheduleCancelDialogState] =
    useState(undefined);
  const [viewVerifyInsuranceState, setViewVerifyInsuranceDialogState] =
    useState(undefined);
  const [viewDmeVerifyInsuranceState, setViewDmeVerifyInsuranceDialogState] =
    useState(undefined);
  const [viewConsentFormsState, setViewConsentFormsDialogState] =
    useState(undefined);
  const [viewDMEConsentFormsState, setViewDMEConsentFormsDialogState] =
    useState(undefined);

    const [viewOrderInfoState, setViewOrderInfoDialogState] =
    useState(undefined);

  const [viewWaitingScheduleState, setViewWaitingScheduleDialogState] =
    useState(undefined);
  const [refreshData, setRefreshData] = useState(false);

  const [viewGetAuthorizationState, setViewGetAuthorizationDialogState] =
    useState(undefined);
  const [viewUpdateStudyInfoState, setViewUpdateStudyInfoDialogState] =
    useState(undefined);
  const [viewDmeUpdateStudyInfoState, setViewDmeUpdateStudyInfoDialogState] =
    useState(undefined);

  const [viewScoreStudyState, setViewScoreStudyDialogState] =
    useState(undefined);

  const [viewCompleteBillingState, setViewCompleteBillingDialogState] =
    useState(undefined);

  const [
    viewUploadInterpretationState,
    setViewUploadInterpretationDialogState,
  ] = useState(undefined);
  const [viewDiscussResultsState, setViewDiscussResultsDialogState] =
    useState(undefined);

  const [viewResultsFollowupState, setViewResultsFollowupDialogState] =
    useState(undefined);
  
  const commonDialog = (e, reason, state) => {
    if (reason === "backdropClick") {
      e.preventDefault();
    } else {
      state(false);
    }
  }

  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
  };

  const fetchPatientData = (row, setState = () => { }) => {
    autoSelectPatient(row[0]);
    const payload = {
      patientId: row[0],
      surveyId: row[10],
      orderId: row[11],
      dmeOrderId: row[164],
      studyId: row[106]
    };
    
    if(payload?.dmeOrderId > 0 && !payload?.orderId){
      getDmeDashboardData(axios, payload)
      .then((res) => {
        setState({ ...res[0][0], patientName: row[2], studyId: row[325] });
      })
      .catch((err) => {
        console.log(err);
      });
    }else{
      getDashboardData(axios, payload)
        .then((res) => {
          setState({ ...res[0][0], patientName: row[2], studyId: row[106] });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    // Get Dashboard Info (Get API)
    async function setDataFirstTime() {
       dashboardGetData(axios)
        .then((res) => {
      // console.log ("Dashboard res = ", res)
          const dataWithPatientName = res["1"]?.map((user) => {
            return {
              ...user,
              patientName: `${user.lastName}, ${user.firstName}`,
              orderDate: user?.orderDate == null ? (user?.visitDate == null ? (user?.dmeOrderDate == null ? user?.dmeVisitDate : user?.dmeOrderDate ) : user?.visitDate) : user?.orderDate,
            };
          });
          setRowData(dataWithPatientName);
          dispatch(updatedashboardData(res["1"]));
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
      columnFilterData(axios, "getFilter").then((res) => {
        const columnData = res[0]
        setColumnState({
          ...columnState,
          userId: userId,
          patientId: columnData.patientId,
          patientName: columnData.patientName,
          dob: columnData.dob,
          physician: columnData.physician,
          patGroup: columnData.patGroup,
          facility: columnData.facility,
          orderDate: columnData.orderDate,
          contactAttempt: columnData.contactAttempt,
          insurance: columnData.insurance,
          studyDate: columnData.studyDate,
          studyType: columnData.studyType,
          stageStatus: columnData.stageStatus,
          prefLocation: columnData.prefLocation
        })
      })
    }
    setDataFirstTime();
  }, [refreshData]);

  function autoSelectPatient(patientId) {
    if (patientId) {
      const paramsName = "patientView";
      const payload = {
        patientId,
      };

      patientsPaginationGetData(axios, paramsName, payload)
        .then((res) => {
          const updatedSelectedPatient = res?.filter(
            (item) => String(item?.patientId) === String(payload.patientId)
          );
          if (updatedSelectedPatient?.length) {
            dispatch(updateSelectedPatient(updatedSelectedPatient[0]));
          }
        })
        .catch((err) => notificationToastError(err?.message));
    }
  }

  const view = (rowData) => {
    autoSelectPatient(rowData[0]);

    let page = `/survey-view`;
    if (rowData[11] > 0) page = `/order-view`;
    if (rowData[164] > 0) page = `/dme-order`;
    navigate(page, { state: { row: rowData, action: "view" } });
  };

  const patientView = async (patientId) => {
      localStorage.setItem('patientId', patientId)
      window.open(`/patient-view?isnewtab=true`, '_blank');
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "90vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  

  return (
   
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      <>
        <MUIDataTable
          clasaName="dsadadadasdsad"
          data={rowData}
          columns={[
            {
              name: "patientIdPat",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "patientNum",
              label: "Patient ID",
              options: {
                display: Boolean(columnState.patientId),
                customBodyRender: (value, tableMeta, updateValue) => {
                  const patientId = tableMeta.rowData[1];
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#715d9c",
                      }}
                      onClick={() => {
                        patientView(tableMeta?.rowData[0]);
                      }}
                    >
                      {patientId}
                    </Box>
                  );
                },
              },
            },

            {
              name: "patientName",
              label: "Patient Name",
              options: { display: Boolean(columnState.patientName)}
            },
            {
              name: "firstName",
              // label: "First Name",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "dob",
              label: "DOB",
              options: {
                setCellProps: () => ({
                  style: { minWidth: "90px" },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                  return <>{dayjs(value).format("MM/DD/YYYY")}</>;
                },
                display: isPhysician && isMobile ? false : Boolean(columnState.dob),
              },
            }, // 4

            {
              name: "gender",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "name",
              label: "Physician",
              options: { display: Boolean(columnState.physician) }
            }, // 6
            {
              name: "groupName",
              label: "Group",
              options: { display: isPhysician && isMobile ? false : Boolean(columnState.patGroup), viewColumns: true, filter: true },
            },
            {
              name: "locationStudy",
              label: "Facility ",
              options: { display: isPhysician && isMobile ? false : Boolean(columnState.facility) },
            }, //8

            {
              name: "orderDate",
              label: "Order Date",
              options: {
                setCellProps: () => ({
                  style: { minWidth: "90px" },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                  return <>{value && dayjs(value).format("MM/DD/YYYY")}</>;
                },
                display: isPhysician && isMobile ? false : Boolean(columnState.orderDate),
              },
            },
            {
              name: "surveyIdSurvey",
              options: { display: false, viewColumns: false, filter: false },
            }, //10
            {
              name: "orderIdOrder",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "locationIdSurvey",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "physicianIdSurvey",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "bloodPressure",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "bmi",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "choking",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "createdAt",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "genderMale",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "heightFt",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "heightIn",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "largeNeck",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "legacyStatus",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "overFifty",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "papMachine",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "snoringSurvey",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "stopbangScore",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "tired",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "weight",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "2nightHSTSubstitute",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "2nightProtocol",
              options: { display: false, viewColumns: false, filter: false },
            },//30
            {
              name: "addlInstructions",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "apneas",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "AsvTitration",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "AvapsTitration",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "bipapOptions",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "bipapStTitration",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "bipapTitration",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "consult",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "cpapIfHstAbnormal",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "cpapOptions",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "cpapTitration",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "dateFinalized",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "dateSubmitted",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "daytimeSleepiness",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "E66_2",
              options: { display: false, viewColumns: false, filter: false },
            },//45
            {
              name: "F51_12",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "F51_3",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "F51_4",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "F51_5",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "fatigue",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_11",
              options: { display: false, viewColumns: false, filter: false },
            },//51
            {
              name: "G47_12",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_13",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_19",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_30",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_31",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_33",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_411",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_419",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_421",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_421_wo_cataplexy",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_61",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_69",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "G47_9",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "gasping",
              options: { display: false, viewColumns: false, filter: false },
            },//65
            {
              name: "homeSleepTest",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "hstNights",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "inappropriateSleep",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "locationIdOrder",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "otherNotes",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "pediatricOptions",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "pediatricSleepStudy",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "physicianIdOrder",
              options: { display: false, viewColumns: false, filter: false },
            }, //73
            {
              name: "psgBaselineStudy",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "psgOptions",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "snoringOrder",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "splitHSTSubstitute",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "splitNightProtocol",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "usingCpap",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "middleInitial",
              options: { display: false, viewColumns: false, filter: false },
            }, // 80

            {
              name: "address1",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "address2",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "city",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "state",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "zip",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "email",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "bestPhone",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "cellPhone",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "primaryInsuranceId",
              options: { display: false, viewColumns: false, filter: false },
            }, //89

            {
              name: "secondaryInsuranceId",
              options: { display: false, viewColumns: false, filter: false },
            }, //90

            {
              name: "primaryPolicy",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "primaryGroup",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "secondaryPolicy",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "secondaryGroup",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "stageId",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "statusNameId",
              options: { display: false, viewColumns: false, filter: false },
            }, // 96

            {
              name: "patientBMI",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "prefInterpPhysId",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "date",
              options: { display: false, viewColumns: false, filter: false },
            }, // 99
            {
              name: "studyTypeId",
              options: { display: false, viewColumns: false, filter: false },
            }, //100

            {
              name: "physicianIdStudy",
              options: { display: false, viewColumns: false, filter: false },
            }, // 101
            {
              name: "locationIdStudy",
              options: { display: false, viewColumns: false, filter: false },
            }, //102
            {
              name: "prefInterpPhysIdStudy",
              options: { display: false, viewColumns: false, filter: false },
            }, //103
            {
              name: "shipDate",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "trackingNum",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "studyIdStudy",
              options: { display: false, viewColumns: false, filter: false },
            }, // 106
            {
              name: "contacAttemptOrder",
              label: "Contact Attempt",
              options: {
                display: Boolean(columnState.contactAttempt),
                viewColumns: true,
                filter: true,
              },
            }, // 107
            {
              name: "primaryInsuranceIdP",
              options: { display: false, viewColumns: false, filter: false },
            }, //108
            {
              name: "planNameP",
              label: "Insurance",
              options: {
                display: Boolean(columnState.insurance),
                viewColumns: true,
                filter: true,
              },
              //options: { display: false, viewColumns: false, filter: false },
            }, //109
            {
              name: "primaryPolicyP",
              options: { display: false, viewColumns: false, filter: false },
            }, //110
            {
              name: "primaryGroupP",
              options: { display: false, viewColumns: false, filter: false },
            }, //111
            {
              name: "secondaryInsuranceIdP",
              options: { display: false, viewColumns: false, filter: false },
            }, //112
            {
              name: "planNameS",
              options: { display: false, viewColumns: false, filter: false },
            }, //113
            {
              name: "secondaryPolicyP",
              options: { display: false, viewColumns: false, filter: false },
            }, //114
            {
              name: "secondaryGroupP",
              options: { display: false, viewColumns: false, filter: false },
            }, //115
            {
              name: "date",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "studyType",
              options: { display: false, viewColumns: false, filter: false },
            }, // 117
            {
              name: "locationStudy",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "npi",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "taxId",
              options: { display: false, viewColumns: false, filter: false },
            }, // 120
            {
              name: "applyDeductible",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "claimAddress",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "coIns",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "copay",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "cptsVerified",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "deductible",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "deductibleMet",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "deductibleYear",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "inNetwork",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "insVerified",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "insVerifiedDate",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "oop",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "oopMet",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "parContact",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "placeOfService",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "planAdmin",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "planType",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "policyEffDate",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "policyHolder",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "policyHolderDob",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "policyType",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "priorAuthRequired",
              options: { display: false, viewColumns: false, filter: false },
            }, //142
            {
              name: "referenceNum",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "referralRequired",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "referralRequiredFrom",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "spokeTo",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "verifiedContact",
              options: { display: false, viewColumns: false, filter: false },
            }, // 147
            {
              name: "activeAuthorization",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "authorizedOn",
              options: { display: false, viewColumns: false, filter: false },
            },

            {
              name: "authorizationStartDate",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "authorizationEndDate",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "authorizationDenied",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "reasonAuthDenial",
              options: { display: false, viewColumns: false, filter: false },
            }, // 153

            {
              name: "dateOfService1",
              options: { display: false, viewColumns: false, filter: false },
            }, // 154
            {
              name: "scoringDate",
              options: { display: false, viewColumns: false, filter: false },
            },
            {
              name: "sentForInterpDate",
              options: { display: false, viewColumns: false, filter: false },
            }, // 156
            {
              name: "stageNameId",
              options: { display: false, viewColumns: false, filter: false },
            }, // 157
            {
              name: "resultsFollowup",
              options: { display: false, viewColumns: false, filter: false },
            }, // 158
            {
              name: "orderOtherTreatment",
              options: { display: false, viewColumns: false, filter: false },
            }, // 159
            {
              name: "time",
              options: { display: false, viewColumns: false, filter: false },
            }, // 160

            {
              name: "reasonReschedulingId",
              options: { display: false, viewColumns: false, filter: false },
            }, // 161
            {
              name: "canceled",
              options: { display: false, viewColumns: false, filter: false },
            }, // 162
            {
              name: "reasonCanceling",
              options: { display: false, viewColumns: false, filter: false },
            }, // 163
            {
              name: "dmeOrderIdOrder",
              options: { display: false, viewColumns: false, filter: false },
            }, //164
            {
              name: "completedTreatmentAppt",
              options: { display: false, viewColumns: false, filter: false },
            }, //165
            {
              name: "canceledTreatmentAppt",
              options: { display: false, viewColumns: false, filter: false },
            }, //166
            {
              name: "incompleteTreatmentAppt",
              options: { display: false, viewColumns: false, filter: false },
            }, //167
            {
              name: "dateTreatmentAppt",
              options: { display: false, viewColumns: false, filter: false },
            }, //168
            {
              name: "techNotes",
              options: { display: false, viewColumns: false, filter: false },
            }, //169
            {
              name: "incompleteTreatmentApptDetails",
              options: { display: false, viewColumns: false, filter: false },
            }, //170
            {
              name: "reasonCancelingTreatmentAppt",
              options: { display: false, viewColumns: false, filter: false },
            }, //171
            {
              name: "studyIncompleteReason",
              options: { display: false, viewColumns: false, filter: false },
            }, //172
            {
              name: "studyIncomplete",
              options: { display: false, viewColumns: false, filter: false },
            }, //173
            {
              name: "studyCompleted",
              options: { display: false, viewColumns: false, filter: false },
            }, //174
            {
              name: "scoringTechId",
              options: { display: false, viewColumns: false, filter: false },
            }, //175
            {
              name: "studyCompletedNotes",
              options: { display: false, viewColumns: false, filter: false },
            }, //176
            {
              name: "studyInfoConfirmed",
              options: { display: false, viewColumns: false, filter: false },
            }, //177
            {
              name: "reasonCancelingId",
              options: { display: false, viewColumns: false, filter: false },
            }, //178
            {
              name: "patUnreachable",
              options: { display: false, viewColumns: false, filter: false },
            }, //179
            {
              name: "contactLaterDate",
              options: { display: false, viewColumns: false, filter: false },
            }, //180
            {
              name: "studyDeclined",
              options: { display: false, viewColumns: false, filter: false },
            }, //181
            {
              name: "hstDeviceId",
              options: { display: false, viewColumns: false, filter: false },
            }, //182
            {
              name: "dateOfService2",
              options: { display: false, viewColumns: false, filter: false },
            }, //183
            {
              name: "dateOfService3",
              options: { display: false, viewColumns: false, filter: false },
            }, //184
            {
              name: "hstDeviceIdNight2",
              options: { display: false, viewColumns: false, filter: false },
            }, //185
            {
              name: "hstDeviceIdNight3",
              options: { display: false, viewColumns: false, filter: false },
            }, //186

            {
              name: "waitingScheduleNotes",
              options: { display: false, viewColumns: false, filter: false },
            }, //187
            {
              name: "studyTypeCompletedId",
              options: { display: false, viewColumns: false, filter: false },
            }, //188

            {
              name: "reasonAuthDenialId",
              options: { display: false, viewColumns: false, filter: false },
            }, //189

            {
              name: "consentFormCompleted",
              options: { display: false, viewColumns: false, filter: false },
            }, // 190
            {
              name: "skipConsentFormCompleted",
              options: { display: false, viewColumns: false, filter: false },
            }, // 191
            {
              name: "reasonAuthorDenial",
              options: { display: false, viewColumns: false, filter: false },
            }, // 192
            //for secondary field
            {
              name: "policyEffDate2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 193
            {
              name: "deductible2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 194
            {
              name: "deductibleMet2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 195
            {
              name: "oop2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 196
            {
              name: "oopMet2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 197
            {
              name: "coIns2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 198
            {
              name: "copay2",
              options: { display: false, viewColumns: false, filter: false },
            }, //199
            {
              name: "inNetwork2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 200
            {
              name: "applyDeductible2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 201
            {
              name: "deductibleYear2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 202
            {
              name: "meditechAcctNum",
              options: { display: false, viewColumns: false, filter: false },
            }, // 203

            {
              name: "studyTypeId2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 204
            {
              name: "studyCompletedNotes2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 205
            {
              name: "studyTypeCompletedId2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 206
            {
              name: "scheduleSecondAppt",
              options: { display: false, viewColumns: false, filter: false },
            }, // 207
            {
              name: "studyCompletedNotes3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 208
            {
              name: "studyTypeCompletedId3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 209
            {
              name: "reasonDecliningId",
              options: { display: false, viewColumns: false, filter: false },
            }, // 210

            {
              name: "secondApptStudyTypeId",
              options: { display: false, viewColumns: false, filter: false },
            }, // 211
            {
              name: "verifiedPrimary",
              options: { display: false, viewColumns: false, filter: false },
            }, // 212
            {
              name: "verifiedSecondary",
              options: { display: false, viewColumns: false, filter: false },
            }, // 213

            {
              name: "date",
              label: "Study Date",
              options: {
                display: Boolean(columnState.studyDate),
                viewColumns: true,
                filter: true,

                setCellProps: () => ({
                  style: { minWidth: "90px" },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                  return <>{value && dayjs(value).format("MM/DD/YYYY")}</>;
                },
              },
            }, // 214
            {
              name: "studyType",
              label: "Study Type",
              options: {
                display: Boolean(columnState.studyType),
                viewColumns: true,
                filter: true,
              },
            }, // 215
            {
              name: "stage",
              label: "Stage - Status",
              options: { display: isPhysician && isMobile ? false : Boolean(columnState.stageStatus) },
            }, // 216

            {
              name: "apptCompleted1",
              options: { display: false, viewColumns: false, filter: false },
            }, // 217
            {
              name: "apptCompleted2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 218
            {
              name: "apptCompleted3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 219
            {
              name: "policySelf",
              options: { display: false, viewColumns: false, filter: false },
            }, // 220
            {
              name: "deviceReturnDate",
              options: { display: false, viewColumns: false, filter: false },
            }, // 221

            {
              name: "autoCPAP",
              options: { display: false, viewColumns: false, filter: false },
            }, // 222
            {
              name: "dmeOrder",
              options: { display: false, viewColumns: false, filter: false },
            }, // 223
            {
              name: "followupSleepSpecialist",
              options: { display: false, viewColumns: false, filter: false },
            }, // 224

            {
              name: "authNum",
              options: { display: false, viewColumns: false, filter: false },
            }, // 225
            {
              name: "npi2",
              options: { display: false, viewColumns: false, filter: false },
            }, //226
            {
              name: "taxId2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 227

            {
              name: "reasonIncompleteStudyId",
              options: { display: false, viewColumns: false, filter: false },
            }, // 228

            {
              name: "reasonIncompleteStudyId1",
              options: { display: false, viewColumns: false, filter: false },
            }, // 229
            {
              name: "reasonIncompleteStudyId2",
              options: { display: false, viewColumns: false, filter: false },
            }, // 230
            {
              name: "reasonIncompleteStudyId3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 231
            {
              name: "studyIncomplete1",
              options: { display: false, viewColumns: false, filter: false },
            }, //232
            {
              name: "studyIncomplete2",
              options: { display: false, viewColumns: false, filter: false },
            }, //233
            {
              name: "studyIncomplete3",
              options: { display: false, viewColumns: false, filter: false },
            }, //234
            {
              name: "followUpOrder",
              options: { display: false, viewColumns: false, filter: false },
            }, //235

            {
              name: "physicianId",
              options: { display: false, viewColumns: false, filter: false },
            }, //236
            {
              name: "followupPhysicianId",
              options: { display: false, viewColumns: false, filter: false },
            }, //237
            {
              name: "parentOrderCpapId",
              options: { display: false, viewColumns: false, filter: false },
            }, //238 - for CPAP order
            {
              name: "parentOrderId",
              options: { display: false, viewColumns: false, filter: false },
            }, //239 - for DME order

            {
              name: "reschedule",
              options: { display: false, viewColumns: false, filter: false },
            }, //240
            {
              name: "dateResultDiscussed",
              options: { display: false, viewColumns: false, filter: false },
            }, //241

            {
              name: "interpDownloaded",
              options: { display: false, viewColumns: false, filter: false },
            }, //242

            {
              name: "orderFollowingPhysicianId",
              options: { display: false, viewColumns: false, filter: false },
            }, //243
            {
              name: "dmeOrderFollowingPhysicianId",
              options: { display: false, viewColumns: false, filter: false },
            }, //244
            {
              name: "followupPhysicianGroupId",
              options: { display: false, viewColumns: false, filter: false },
            }, //245
            {
              name: "orderingPhysicianGroupId",
              options: { display: false, viewColumns: false, filter: false },
            }, //246
            {
              name: "parentOrPhysicianGroupId",
              options: { display: false, viewColumns: false, filter: false },
            }, //247

            {
              name: "inLabAuthRequired",
              options: { display: false, viewColumns: false, filter: false },
            }, //248

            {
              name: "hstAuthRequired",
              options: { display: false, viewColumns: false, filter: false },
            }, //249

            {
              name: "inLab",
              options: { display: false, viewColumns: false, filter: false },
            }, //250

            {
              name: "hst",
              options: { display: false, viewColumns: false, filter: false },
            }, //251

            {
              name: "followUpParentOrderCpapId",
              options: { display: false, viewColumns: false, filter: false },
            }, //252
            {
              name: "dmeFollowUpparentOrderId",
              options: { display: false, viewColumns: false, filter: false },
            }, //253
            {
              name: "planNameT",
              options: { display: false, viewColumns: false, filter: false },
            }, //254
            {
              name: "tertiaryPolicyP",
              options: { display: false, viewColumns: false, filter: false },
            }, //255
            {
              name: "tertiaryGroupP",
              options: { display: false, viewColumns: false, filter: false },
            }, //256
            {
              name: "tertiaryInsuranceIdP",
              options: { display: false, viewColumns: false, filter: false },
            }, //257

            {
              name: "tertiaryPolicy",
              options: { display: false, viewColumns: false, filter: false },
            }, //258
            {
              name: "tertiaryGroup",
              options: { display: false, viewColumns: false, filter: false },
            }, //259
            {
              name: "tertiaryInsuranceId",
              options: { display: false, viewColumns: false, filter: false },
            }, //260
            {
              name: "prefLocation",
              label: "Preferred Facility",
              options: {
                display: Boolean(columnState.prefLocation),
                viewColumns: true,
                filter: true,
              },
            }, // 261
            {
              name: "requestedReschedule",
              options: { display: false, viewColumns: false, filter: false },
            }, //262
            {
              name: "requestedCancelStudy",
              options: { display: false, viewColumns: false, filter: false },
            }, //263
            {
              name: "reasonApptResched",
              options: { display: false, viewColumns: false, filter: false },
            }, //264
            {
              name: "reasonApptCanc",
              options: { display: false, viewColumns: false, filter: false },
            }, //265
            {
              name: "interpFileId",
              options: { display: false, viewColumns: false, filter: false },
            }, //266

             //for tertiary field
             {
              name: "policyEffDate3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 267
            {
              name: "deductible3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 268
            {
              name: "deductibleMet3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 269
            {
              name: "oop3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 270
            {
              name: "oopMet3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 271
            {
              name: "coIns3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 272
            {
              name: "copay3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 273
            {
              name: "inNetwork3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 274
            {
              name: "applyDeductible3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 275
            {
              name: "deductibleYear3",
              options: { display: false, viewColumns: false, filter: false },
            }, // 276
            {
              name: "verifiedTertiary",
              options: { display: false, viewColumns: false, filter: false },
            }, // 277
            {
              name: "patientConfirmedScheduledStudy",
              options: { display: false, viewColumns: false, filter: false },
            }, // 278
            {
              name: "patientConfirmedDateTime",
              options: { display: false, viewColumns: false, filter: false },
            }, // 279
            {
              name: "patientConfirmedBySMS",
              options: { display: false, viewColumns: false, filter: false },
            }, // 280
            {
              name: "uploadDate",
              options: { display: false, viewColumns: false, filter: false },
            }, // 281
            {
              name: "deviceDate",
              options: { display: false, viewColumns: false, filter: false },
            }, // 282
            {
              name: "contacAttemptDme",
              options: { display: false, viewColumns: false, filter: false },
            }, // 283
            {
              name: "studyDeclinedDme",
              options: { display: false, viewColumns: false, filter: false },
            }, // 284
            {
              name: "patUnreachableDme",
              options: { display: false, viewColumns: false, filter: false },
            }, // 285
            {
              name: "studyDeclinedReasonDme",
              options: { display: false, viewColumns: false, filter: false },
            }, // 286
            {
              name: "waitingScheduleNotesDme",
              options: { display: false, viewColumns: false, filter: false },
            }, //287
            {
              name: "contactLaterDateDme",
              options: { display: false, viewColumns: false, filter: false },
            }, //288
            {
              name: "noLoginPracticeFollowing",
              options: { display: false, viewColumns: false, filter: false },
            }, //289
            {
              name: "noLoginPracticeOrdering",
              options: { display: false, viewColumns: false, filter: false },
            }, //290
            {
              name: "snoring",
              options: { display: false, viewColumns: false, filter: false },
            }, //291
            {
              name: "verbiage",
              options: { display: false, viewColumns: false, filter: false },
            }, //292
            {
              name: "validity",
              options: { display: false, viewColumns: false, filter: false },
            }, //293
            {
              name: "visitDate",
              options: { display: false, viewColumns: false, filter: false },
            }, //294
            {
              name: "noLoginPhysician",
              options: { display: false, viewColumns: false, filter: false },
            }, //295
            {
              name: "orderingPhysicianPhone",
              options: { display: false, viewColumns: false, filter: false },
            }, //296
            {
              name: "orderingPhysicianMobileNum",
              options: { display: false, viewColumns: false, filter: false },
            }, //297
            {
              name: "invalidStudyType",
              options: { display: false, viewColumns: false, filter: false },
            }, //298
            {
              name: "invalidDiagnosticCode",
              options: { display: false, viewColumns: false, filter: false },
            }, //299
            {
              name: "invalidSign",
              options: { display: false, viewColumns: false, filter: false },
            }, //300
            {
              name: "invalidOther",
              options: { display: false, viewColumns: false, filter: false },
            }, //301
            {
              name: "invalidOtherText",
              options: { display: false, viewColumns: false, filter: false },
            }, //302
            {
              name: "physicianIdDmeOrder",
              options: { display: false, viewColumns: false, filter: false },
            }, //303
            {
              name: "reasonIncomplete",
              options: { display: false, viewColumns: false, filter: false },
            }, //304
            {
              name: "physicianName",
              options: { display: false, viewColumns: false, filter: false },
            }, //305
            {
              name: "outTrackingNum",
              options: { display: false, viewColumns: false, filter: false },
            },//306
            {
              name: "pickup",
              options: { display: false, viewColumns: false, filter: false },
            },//307
            {
              name: "callBackInvalidOrder",
              options: { display: false, viewColumns: false, filter: false },
            },//308
            {
              name: "interpretationDate",
              options: { display: false, viewColumns: false, filter: false },
            },//309
            {
              name: "followUpAuthorizationDate",
              options: { display: false, viewColumns: false, filter: false },
            },//310
            {
              name: "priorAuthRequired2",
              options: { display: false, viewColumns: false, filter: false },
            },//311
            {
              name: "priorAuthRequired3",
              options: { display: false, viewColumns: false, filter: false },
            },//312
            {
              name: "paymentReceived",
              options: { display: false, viewColumns: false, filter: false },
            },//313
            {
              name: "policyType2",
              options: { display: false, viewColumns: false, filter: false },
            },//314
            {
              name: "planAdmin2",
              options: { display: false, viewColumns: false, filter: false },
            },//315
            {
              name: "spokeTo2",
              options: { display: false, viewColumns: false, filter: false },
            },//316
            {
              name: "referenceNum2",
              options: { display: false, viewColumns: false, filter: false },
            },//317
            {
              name: "policyType3",
              options: { display: false, viewColumns: false, filter: false },
            },//318
            {
              name: "planAdmin3",
              options: { display: false, viewColumns: false, filter: false },
            },//319
            {
              name: "spokeTo3",
              options: { display: false, viewColumns: false, filter: false },
            },//320
            {
              name: "referenceNum3",
              options: { display: false, viewColumns: false, filter: false },
            },//321
            {
              name: "followUpInterpretationNotes",
              options: { display: false, viewColumns: false, filter: false },
            },//322
            {
              name: "activeAuthorization2",
              options: { display: false, viewColumns: false, filter: false },
            }, //323
            {
              name: "activeAuthorization3",
              options: { display: false, viewColumns: false, filter: false },
            }, //324
            {  
              name: "dmeStudyId",
              options: { display: false, viewColumns: false, filter: false },
            },//325
            {  
              name: "orderingPhysicianGroupId",
              options: { display: false, viewColumns: false, filter: false },
            }, //326
            {  
              name: "followupPhysicianIdGroupId",
              options: { display: false, viewColumns: false, filter: false },
            }, //327
            
            {
              //Action button parts
              name: "Actions",
              label: "Actions",
              options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
               
                  /*  if (
                    tableMeta.rowData[96] === 1 &&
                    tableMeta.rowData[157] === 6
                  ) {
                    // if  Recommendations Stage and Pending Review status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={openResultsFollowupDialog.bind(
                            null,
                            tableMeta.rowData
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Results Followup
                        </Button>
                      </Box>
                    );
                    */
                  if (
                    tableMeta.rowData[96] === 18 ||
                    tableMeta.rowData[96] === 17
                  ) {
                    // status Adherence
                  } else if (tableMeta.rowData[96] === 26 || tableMeta.rowData[96] === 27 || tableMeta.rowData[96] === 23 ) {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                      
                    <Button
                    variant="contained"
                    size="small"
                    onClick={fetchPatientData.bind(
                      null,
                      tableMeta.rowData,
                      setViewRescheduleStudyDialogState
                    )}
                    // sx={{ marginTop: "1rem" }}
                  >
                    Reschedule /Cancel Study
                  </Button>
                  <Button
                            variant="contained"
                            size="small"
                            onClick={fetchPatientData.bind(
                              null,
                              tableMeta.rowData,
                              setViewWaitingScheduleDialogState
                            )}
                            // sx={{ marginTop: "1rem" }}
                          >
                            Waiting to Reschedule
                          </Button>
                </Box>
                    )

                  } else if (tableMeta.rowData[96] === 16) {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >

                        { (isReviewResults == 1 &&
                          tableMeta.rowData[242] != 1 &&  ( (groupId.includes(1) && tableMeta.rowData[326] !== tableMeta.rowData[327]) || ( tableMeta.rowData[290] !== 1 && tableMeta.rowData[326] !== tableMeta.rowData[327] && groupId.includes(tableMeta.rowData[326])  ) )  ) && (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={fetchPatientData.bind(
                                null,
                                tableMeta.rowData,
                                setViewInterpretationAvailableDialogState
                              )}
                            >
                              Interpretation Available: Referred to Sleep
                              Specialist
                            </Button>
                          )}

                        { (isDiscussResults == 1 &&
                          tableMeta.rowData[241]?.length != 10 &&
                          (groupId.includes(1) || ( tableMeta.rowData[289] !==1 && groupId.includes(tableMeta.rowData[327])  ) )) && (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={fetchPatientData.bind(
                                null,
                                tableMeta.rowData,
                                setViewInterpretationAvailableDiscussDialogState
                              )}
                            >
                              Discuss Results with Patient
                            </Button>
                          )}
                      </Box>
                    );
                  } else if (tableMeta.rowData[96] === 19) {
                    // If Complted status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        {
                          tableMeta.rowData[164] > 0 && !tableMeta.rowData[11] ? 
                          <Button
                              variant="contained"
                              size="small"
                              onClick={fetchPatientData.bind(
                                null,
                                tableMeta.rowData,
                                setViewDmeUpdateStudyInfoDialogState
                              )}
                            >
                              Confirm Study Info (Tx)
                            </Button>
                          :
                            <Button
                              variant="contained"
                              size="small"
                              onClick={fetchPatientData.bind(
                                null,
                                tableMeta.rowData,
                                setViewUpdateStudyInfoDialogState
                              )}
                            >
                              Confirm Study Info
                            </Button>
                        }
                      </Box>
                    );
                    /*       } else if (tableMeta.rowData[96] === 20) {
                    // If study Confirmed
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={openCompleteBillingDialog.bind(
                            null,
                            tableMeta.rowData
                          )}
                        >
                          Complete Billing
                        </Button>
                      </Box>
                    );
                    */
                  } else if (tableMeta.rowData[96] === 15 && tableMeta.rowData[157] === 6) {
                    // If Completed status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                          <Button
                              variant="contained"
                              size="small"
                              onClick={fetchPatientData.bind(
                                null,
                                tableMeta.rowData,
                                setViewInterpretationAvailableDiscussDialogState
                              )}
                            >
                              Discuss Results with Patient
                            </Button>

                      </Box>
                    );

                  } else if (tableMeta.rowData[96] === 15) {
                    // If Completed status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewScoreStudyDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Score Study
                        </Button>
                      </Box>
                    );
                  } else if (tableMeta.rowData[96] === 20) {
                    // If Completed status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewUploadInterpretationDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Upload Interpretation
                        </Button>
                      </Box>
                    );
                  } else if (
                    tableMeta.rowData[96] === 13 ||
                    tableMeta.rowData[96] === 24
                  ) {
                    // if Completed Consent Forms status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        {
                          tableMeta.rowData[164] > 0 && !tableMeta.rowData[11] ?
                            <Button
                              variant="contained"
                              size="small"
                              onClick={fetchPatientData.bind(
                                null,
                                tableMeta.rowData,
                                setViewDmeUpdateStudyInfoDialogState
                              )}
                            >
                              Complete DME Appointment (Tx)
                            </Button>
                            :
                            <Button
                              variant="contained"
                              size="small"
                              onClick={fetchPatientData.bind(
                                null,
                                tableMeta.rowData,
                                setViewUpdateStudyInfoDialogState
                              )}
                            >
                              Confirm Sleep Study
                            </Button>
                        }
                      </Box>
                    );
                  } else if (
                    tableMeta.rowData[96] === 25 &&
                    ( tableMeta.rowData[142] === 1 || tableMeta.rowData[311] === 1 || tableMeta.rowData[312] === 1)
                  ) {
                    // if Insurance Verified status and priorAuthRequired checked
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        { ( ( (tableMeta.rowData[148] + tableMeta.rowData[323] + tableMeta.rowData[324] ) != (tableMeta.rowData[142] + tableMeta.rowData[311] + tableMeta.rowData[312] )  ) && !tableMeta.rowData[152] && (!tableMeta.rowData[310] ||  moment(tableMeta.rowData[310]).isSameOrBefore(new Date()) ) ) &&
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewGetAuthorizationDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Get Authorization
                        </Button>
                        }
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewRescheduleCancelDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Request Reschedule / Cancel Study
                        </Button>
                       
                      </Box>
                    );
                  } else if (
                    (tableMeta.rowData[96] === 10 ||
                    tableMeta.rowData[96] === 11) && tableMeta.rowData[157] === 4
                   ) {
                    // if (Insurance Verified  status ANd Not priorAuthRequired) || Authorized status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        {
                          tableMeta.rowData[164] > 0 && !tableMeta.rowData[11] ? 
                          <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewDMEConsentFormsDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Complete Consent Forms (TX)
                        </Button> :
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewConsentFormsDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Complete Consent Forms
                        </Button>
                        }

                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewRescheduleCancelDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Request Reschedule / Cancel Study
                        </Button>

                      </Box>
                    );

                  } else if (
                    (tableMeta.rowData[96] === 12 && tableMeta.rowData[157] === 4)
                  ) {
                    return (
                      <>
                    <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                    >
                    <Button
                    variant="contained"
                    size="small"
                    onClick={fetchPatientData.bind(
                      null,
                      tableMeta.rowData,
                      setViewRescheduleStudyDialogState
                    )}
                    // sx={{ marginTop: "1rem" }}
                  >
                    Reschedule /Cancel Study
                  </Button>
                </Box>
                <Button
                variant="contained"
                size="small"
                onClick={fetchPatientData.bind(
                  null,
                  tableMeta.rowData,
                  setViewWaitingScheduleDialogState
                )}
                sx={{ marginTop: "1rem" }}
              >
                Waiting to Reschedule
              </Button>
              </>
                    )

                 

                  } else if ( !tableMeta.rowData[164]  &&
                    (tableMeta.rowData[96] === 7 ||
                      tableMeta.rowData[96] === 14 ) &&
                    (tableMeta.rowData[157] === 3 ||
                      tableMeta.rowData[157] === 4)
                  ) {
                    // if Study Scheduled/ Resheduled status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewVerifyInsuranceDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Verify Insurance
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewRescheduleCancelDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Request Reschedule / Cancel Study
                        </Button>
                      </Box>
                    );
                  } else if (tableMeta.rowData[96] === 3) {
                    if (tableMeta.rowData[157] === 13) {
                      // DME Order Signed status
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                            <Button
                            variant="contained"
                            size="small"
                            onClick={fetchPatientData.bind(
                              null,
                              tableMeta.rowData,
                              setViewTreatmentScheduleStudyDialogState
                            )}
                            // sx={{ marginTop: "1rem" }}
                          >
                            Schedule Tx/Education
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={fetchPatientData.bind(
                              null,
                              tableMeta.rowData,
                              setViewWaitingScheduleDialogState
                            )}
                            // sx={{ marginTop: "1rem" }}
                          >
                            Waiting to Schedule Tx/Education
                          </Button>

                        
                        </Box>
                      );
                    } else {
                      // Order Signed status
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                           <Button
                            variant="contained"
                            size="small"
                            onClick={fetchPatientData.bind(
                              null,
                              tableMeta.rowData,
                              setViewScheduleStudyDialogState
                            )}
                            // sx={{ marginTop: "1rem" }}
                          >
                            Schedule
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={fetchPatientData.bind(
                              null,
                              tableMeta.rowData,
                              setViewWaitingScheduleDialogState
                            )}
                            // sx={{ marginTop: "1rem" }}
                          >
                            Waiting to Schedule
                          </Button>

                         
                        </Box>
                      );
                    }
                  } else if (
                    tableMeta.rowData[96] == 21 &&
                    tableMeta.rowData[157] === 14
                  ) {
                    //if Treatment scheduled exist
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewUploadComplianceDialogState
                          )}
                        >
                          Upload Compliance Report
                        </Button>
                      </Box>
                    );

                  } else if (  (tableMeta.rowData[164] > 0) &&
                    (tableMeta.rowData[96] === 7 ||
                      tableMeta.rowData[96] === 14 ) &&
                    (tableMeta.rowData[157] === 14 || tableMeta.rowData[157] === 4)
                  ) {
                    // if Study Scheduled/ Resheduled status
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewDmeVerifyInsuranceDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Verify Insurance(TX)
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewRescheduleCancelDialogState
                          )}
                          // sx={{ marginTop: "1rem" }}
                        >
                          Request Reschedule / Cancel Study (TX)
                        </Button>
                      </Box>
                    );

                  } else if (
                    tableMeta.rowData[96] == 7 &&
                    tableMeta.rowData[157] === 14
                  ) {
                    //if Treatment scheduled exist
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={fetchPatientData.bind(
                            null,
                            tableMeta.rowData,
                            setViewTreatmentAppointmentDialogState
                          )}
                        >
                          Complete / Cancel / Update
                        </Button>
                      </Box>
                    );
                  } else if (tableMeta.rowData[164] > 0) {
                    // if DmeOrderIdOrder exist (Order created)

                    if (isAllPatients == 1) {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={view.bind(null, tableMeta.rowData)}
                            // sx={{ marginTop: "1rem" }}
                          >
                            Review /Reject /Sign DME Order 
                          </Button>
                        </Box>
                      );
                    } else if  (tableMeta.rowData[244] === userId ||
                        tableMeta.rowData[303] === userId) 
                  
                     {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={view.bind(null, tableMeta.rowData)}
                            // sx={{ marginTop: "1rem" }}
                          >
                            Review /Reject /Sign DME Order 
                          </Button>
                        </Box>
                      );
                    } 

                  } else if (tableMeta.rowData[11] > 0) {
                    // if orderIdOrder exist (Order created)

                    if (
                      tableMeta.rowData[238] > 0 &&
                      (tableMeta.rowData[237] === userId ||
                        tableMeta.rowData[73] === userId)
                    ) {

                      if (
                        tableMeta.rowData[295] > 0 
                      ) {

                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "12px",
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              onClick={view.bind(null, tableMeta.rowData)}
                              // sx={{ marginTop: "1rem" }}
                            >
                        
                              Download /Upload and FAX
                            </Button>
                          </Box>
                        );

                      } else {
                      
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={view.bind(null, tableMeta.rowData)}
                            // sx={{ marginTop: "1rem" }}
                          >
                    
                            Review /Reject /Sign
                          </Button>
                        </Box>
                      );
                    }
                    } else if (
                      tableMeta.rowData[238] > 0 &&
                      tableMeta.rowData[101] !== tableMeta.rowData[73]
                    ) {

                      if (
                        tableMeta.rowData[295] > 0 
                      ) {

                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "12px",
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              onClick={view.bind(null, tableMeta.rowData)}
                              // sx={{ marginTop: "1rem" }}
                            >
                        
                            Download /Upload and FAX
                            </Button>
                          </Box>
                        );

                      } else {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          
                          <Button
                            variant="contained"
                            size="small"
                            onClick={view.bind(null, tableMeta.rowData)}
                            // sx={{ marginTop: "1rem" }}
                          >
                  
                            Review /Reject /Sign
                          </Button>
                        </Box>
                      );
                      }
                    } else if (tableMeta.rowData[238] > 0) {

                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                
                          {(isDiscussResults == 1 &&
                          tableMeta.rowData[241]?.length != 10 &&
                          (groupId.includes(1) || groupId.includes(tableMeta.rowData[245]) ) ) && (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={fetchPatientData.bind(
                              null,
                              tableMeta.rowData,
                              setViewInterpretationAvailableDiscussDialogState
                            )}
                          >
                            Discuss Results with Patient
                          </Button>
                          )}
                          <Button
                            variant="contained"
                            size="small"
                            onClick={view.bind(null, tableMeta.rowData)}
                            // sx={{ marginTop: "1rem" }}
                          >
                          
                            Review /Reject /Sign
                          </Button>
                        </Box>
                      );

                    } else if (tableMeta.rowData[96] === 28) {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            
                            onClick={fetchPatientData.bind(
                              null,
                              tableMeta.rowData,
                              setViewOrderInfoDialogState
                            )}
                          
                          >
                            Invalid Referral Follow Up
                          </Button>
                        </Box>
                      );
                    

                    } else {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            onClick={view.bind(null, tableMeta.rowData)}
                            // sx={{ marginTop: "1rem" }}
                          >
                          
                            Review /Reject /Sign
                          </Button>
                        </Box>
                      );
                    }


                  } else if (tableMeta.rowData[10] > 0) {
                    //if Survey exist
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          onClick={view.bind(null, tableMeta.rowData)}
                          // sx={{ marginTop: "1rem" }}
                        >
                          View/Close
                        </Button>
                      </Box>
                    );
                  }
                },
              },
            },
          ]}
          options={{
            filterType: 'multiselect',
            selectableRows: 'none', // <===== will turn off checkboxes in rows
            responsive: "vertical",
            download:false,
            onViewColumnsChange: (columnName, act) => {
              let payload = { ...columnState, [columnObj[columnName]]: act === "add" ? 1 : 0 }
              
              setColumnState({
                ...columnState,
                [columnObj[columnName]]:act === "add" ? 1 : 0
              })

              columnFilterData(axios,"updateFilter",payload).then((res)=>{
                if(res){
                  columnFilterData(axios,"getFilter").then((res)=>{
                  })
                }
              })
              
              // if (columnName === "contacAttemptOrder") {
              //   if (act === "add") setContactAttemptState(true);
              //   else if (act === "remove") setContactAttemptState(false);
              // }
              // if (columnName === "date") {
              //   if (act === "add") setStudyDateState(true);
              //   else if (act === "remove") setStudyDateState(false);
              // }
              // if (columnName === "studyType") {
              //   if (act === "add") setStudyTypeState(true);
              //   else if (act === "remove") setStudyTypeState(false);
              // }
              // if (columnName === "groupName") {
              //   if (act === "add") setGroupState(true);
              //   else if (act === "remove") setGroupState(false);
              // }
              // if (columnName === "planNameP") {
              //   if (act === "add") setPlanNamePState(true);
              //   else if (act === "remove") setPlanNamePState(false);
              // }
          
            },

            // tableBodyHeight: rowData?.length && "calc(100vh - 200px)",
          }}
        />
      </>

      {viewInterpretationAvailableState && (
        <InterpretationAvailableDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewInterpretationAvailableDialogState)}
          row={viewInterpretationAvailableState}
          setViewCloseDialogState={(state) => {
            setViewInterpretationAvailableDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewInterpretationAvailableDiscussState && (
        <InterpretationAvailableDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewInterpretationAvailableDiscussDialogState)}
          row={viewInterpretationAvailableDiscussState}
          discussResults={true}
          setViewCloseDialogState={(state) => {
            setViewInterpretationAvailableDiscussDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

{viewRescheduleCancelState && (
        <RescheduleCancelDialog
          handleCloseSchedule={(e, reason) => commonDialog(e, reason,setViewRescheduleCancelDialogState)}
          row={viewRescheduleCancelState}
          setViewCloseDialogState={(state) => {
            setViewRescheduleCancelDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewRescheduleStudyState && (
        <RescheduleStudyDialog
          handleCloseSchedule={(e, reason) => commonDialog(e, reason,setViewRescheduleStudyDialogState)}
          row={viewRescheduleStudyState}
          setViewCloseDialogState={(state) => {
            setViewRescheduleStudyDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}
      {viewVerifyInsuranceState && (
        <VerifyInsuranceDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewVerifyInsuranceDialogState)}
          row={viewVerifyInsuranceState}
          setViewCloseDialogState={(state) => {
            setViewVerifyInsuranceDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

{viewDmeVerifyInsuranceState && (
        <DmeVerifyInsuranceDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewDmeVerifyInsuranceDialogState)}
          row={viewDmeVerifyInsuranceState}
          setViewCloseDialogState={(state) => {
            setViewDmeVerifyInsuranceDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewConsentFormsState && (
        <ConsentFormsDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewConsentFormsDialogState)}
          row={viewConsentFormsState}
          setViewCloseDialogState={(state) => {
            setViewConsentFormsDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewDMEConsentFormsState && (
        <DmeConsentFormsDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewDMEConsentFormsDialogState)}
          row={viewDMEConsentFormsState}
          setViewCloseDialogState={(state) => {
            setViewDMEConsentFormsDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewTreatmentScheduleStudyState && (
        <TreatmentScheduleStudyDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewTreatmentScheduleStudyDialogState)}
          row={viewTreatmentScheduleStudyState}
          setViewCloseDialogState={(state) => {
            setViewTreatmentScheduleStudyDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewTreatmentAppointmentState && (
        <TreatmentAppointmentDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewTreatmentAppointmentDialogState)}
          row={viewTreatmentAppointmentState}
          setViewCloseDialogState={(state) => {
            setViewTreatmentAppointmentDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewUploadComplianceState && (
        <UploadComplianceDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewUploadComplianceDialogState)}
          row={viewUploadComplianceState}
          setViewCloseDialogState={(state) => {
            setViewUploadComplianceDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {/* rescheule popup */}
      {viewScheduleStudyState && (
        <ScheduleStudyDialog
          handleCloseSchedule={(e, reason) => commonDialog(e, reason,setViewScheduleStudyDialogState)}
          handleRescheduleClose={(e, reason) => commonDialog(e, reason,setViewScheduleStudyDialogState)}
          row={viewScheduleStudyState}
          setViewCloseDialogState={(state) => {
            setViewScheduleStudyDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

{viewOrderInfoState && (
        <OrderInfoDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewOrderInfoDialogState)}
          row={viewOrderInfoState}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setViewCloseDialogState={(state) => {
            setViewOrderInfoDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewWaitingScheduleState && (
        <WaitingScheduleDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewWaitingScheduleDialogState)}
          row={viewWaitingScheduleState}
          setViewCloseDialogState={(state) => {
            setViewWaitingScheduleDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewGetAuthorizationState && (
        <GetAuthorizationDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewGetAuthorizationDialogState)}
          row={viewGetAuthorizationState}
          setViewCloseDialogState={(state) => {
            setViewGetAuthorizationDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewCompleteBillingState && (
        <CompleteBillingDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewCompleteBillingDialogState)}
          row={viewCompleteBillingState}
          setViewCloseDialogState={(state) => {
            setViewCompleteBillingDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewScoreStudyState && (
        <ScoreStudyDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewScoreStudyDialogState)}
          row={viewScoreStudyState}
          setViewCloseDialogState={(state) => {
            setViewScoreStudyDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewUpdateStudyInfoState && (
        <UpdateStudyInfoDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewUpdateStudyInfoDialogState)}
          row={viewUpdateStudyInfoState}
          setViewCloseDialogState={(state) => {
            setViewUpdateStudyInfoDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewDmeUpdateStudyInfoState && (
        <DmeUpdateStudyInfoDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewDmeUpdateStudyInfoDialogState)}
          row={viewDmeUpdateStudyInfoState}
          setViewCloseDialogState={(state) => {
            setViewDmeUpdateStudyInfoDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewUploadInterpretationState && (
        <UploadInterpretationDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewUploadInterpretationDialogState)}
          row={viewUploadInterpretationState}
          setViewCloseDialogState={(state) => {
            setViewUploadInterpretationDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewResultsFollowupState && (
        <ResultsFollowupDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewResultsFollowupDialogState)}
          row={viewResultsFollowupState}
          updateInput={updateInput}
          setViewCloseDialogState={(state) => {
            setViewResultsFollowupDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}

      {viewDiscussResultsState && (
        <DiscussResultsDialog
          handleClose={(e, reason) => commonDialog(e, reason,setViewDiscussResultsDialogState)}
          row={viewDiscussResultsState}
          setViewCloseDialogState={(state) => {
            setViewDiscussResultsDialogState(state);
            setRefreshData(!refreshData);
          }}
          notificationToastSuccess={(message) =>
            dispatch(setSnackbarMessage({ message, type: "success" }))
          }
          notificationToastError={(message) =>
            dispatch(setSnackbarMessage({ message, type: "error" }))
          }
        />
      )}
    </Container>
  );
}
