import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useAxios } from "../../../hooks/auth";
import { useNavigate } from "react-router-dom";
import CustomizedDialogs from "./ScrollDialog";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { SearchPatient } from "../../../components/misc/SearchPatient";
import {
  Alert,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "../../../../node_modules/@mui/material/index";
import { useState } from "react";
import * as React from "react";
import PatientSearch from "../../../pages/PatientSearch";
import { updateSelectedPatient } from "../../../store/slices/selectedPatientSlice";
import PhysicianPulldown from "../../../components/misc/PhysicianPulldown";
import InsuranceInfo from "./InsuranceInfo";
import moment from "moment";
import phoneNumberValidator from "../../../utils/phoneNumberValidator";
import FileUpload from "./FileUpload/FileUpload";
import SelectedPatients from "./selectPatients/SelectedPatients";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../utils/notification";
import { ToastContainer } from "react-toastify";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../../../constants/sideBarwidth";
import { surveyPostData } from "../../../axios/api";
import PatientInfo from "./PatientInfo";
import { readOnlyBackground } from "../../../Theme/theme";

const returnButton = (formState, createSurveyOrder, createSurvey,isSaveButtonDisabled,active) => {
  if (
    formState.stopbangScore > 2 &&
    (formState.snoring || formState.tired || formState.choking)
  ) {
    return (
      <Box sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
        <Button variant="contained" onClick={createSurveyOrder} disabled={active}>
          CREATE SURVEY AND Create Order
        </Button>
      </Box>
    );
  } else {
    return (
      <Box sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
        <Button variant="contained" disabled={isSaveButtonDisabled || active} onClick={createSurvey} >
          CREATE SURVEY
        </Button>
      </Box>
    );
  }
};

function NewSurvey() {
  const dispatch = useDispatch();
  const selectedPatient = useSelector(
    (state) => state.selectedPatientSelector?.value
  );
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const [formErrorState, setFormErrorState] = useState({
    firstName: "",
    lastName: "",
    interpPhysId: "",
  });
  const [BMIErrorState, setBMIErrorState] = useState({
    heightFeet: "",
    heightInches: "",
    weight: "",
  });
  const [formState, setFormState] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [surveyCheck, setSurveyCheck] = useState({});
  const [BMI, setBMIState] = useState({});
  const [active, setActive] = useState()
  const isMobile = useMediaQuery("(max-width:800px)");
  const updateInput = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value,
    });
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false)
    }
  };

  const updateCheckBox = (e) => {
    setFormState({
      ...formState,
      [e.target.id || e.target.name]: Number(e.target.checked),
    });
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false)
    }
  };
  function getErrorValidationProperty(key) {
    return {
      ...(formState[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }
  function getErrorValidationPropertyHieght(key) {
    return {
      ...(BMI[key] === "" && {
        error: "true",
        helperText: "Required",
      }),
    };
  }

  React.useEffect(() => {
    const { formattedPhoneNumber: bestPhone } = phoneNumberValidator(
      selectedPatient?.bestPhone
    );
    const { formattedPhoneNumber: cellPhone } = phoneNumberValidator(
      selectedPatient?.cellPhone
    );
   
      const newFormState = {
        ...selectedPatient,
        ...formState,
        bestPhone,
        cellPhone
     
       };

       if (selectedPatient?.patBMI > 25) updateSurveyCheck({ target: { id: "patientBMI", checked: true } });

    let stopbangScore = 0;
    if (selectedPatient?.dob && calculate_age(selectedPatient?.dob) > 50) {
      newFormState.overFifty = 1;
      stopbangScore++;
    }

    if (selectedPatient?.gender === "Male") {
      newFormState.genderMale = 1;
      stopbangScore++;
    }

    if (stopbangScore) {
      newFormState.stopbangScore = stopbangScore;
      setSurveyCheck({
        overFifty: newFormState.overFifty,
        genderMale: newFormState.genderMale,
      });
    }

    setFormState(newFormState);
    setBMIState({
      ...BMI,
      heightFeet: newFormState?.heightFeet,
      heightInches: newFormState?.heightInches,
      patBMI: newFormState?.patBMI,
      patWeight: newFormState?.patWeight,
      weight: newFormState?.patWeight,
      bmi: newFormState?.patBMI
    })
    if (selectedPatient?.dob) {
      setDOB(selectedPatient?.dob);
    }
 
  }, [selectedPatient]);
  

  const [isSelectPatient, setIsSelectPatient] = useState(true);
  const unselectPatient = () => {
    dispatch(updateSelectedPatient(undefined));
    setFormState({});
    setDOB(null);
    setIsSelectPatient(true);
  };
  const navigate = useNavigate();
  const axios = useAxios();
  const [DOB, setDOB] = React.useState(null);
  const updateDOB = (newValue) => {
    setDOB(newValue);
  };
  const [errorDOB, setErrorDOB] = React.useState(false);
  const validatePositiveNumber = (id, value) => {
    if (id === "heightInches") {
      if (value !== "" && !Number.isNaN(value) && Number(value) > -1) {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: false },
        }));
      } else {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: true },
        }));
      }
    } else {
      if (value !== "" && !Number.isNaN(value) && Number(value) > 0) {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: false },
        }));
      } else {
        setBMIErrorState((prevState) => ({
          ...prevState,
          [id]: { error: true },
        }));
      }
    }
  };

  const updateBMIInput = (e) => {
    const { id, value } = e?.target;
    if (id === "heightFeet" || id === "heightInches" || id === "weight") {
      validatePositiveNumber(id, value);
    }
    const newBMIState = { ...BMI, [e.target.id]: e.target.value };
    const height =
      parseInt(newBMIState.heightFeet) * 12 +
      parseInt(newBMIState.heightInches);
    const bmi = Math.round((newBMIState.weight * 703) / Math.pow(height, 2));
    if (!isNaN(bmi)) {
      newBMIState.bmi = bmi;
    }
    if (bmi > 25) {
      updateSurveyCheck({ target: { id: "patientBMI", checked: true } });
    } else updateSurveyCheck({ target: { id: "patientBMI", checked: false } });
    setBMIState(newBMIState);
  };

  const updateSurveyCheck = (e) => {
    const newSurveyState = {
      ...surveyCheck,
      [e.target.id]: e.target.checked ? 1 : 0,
    };

    let sum = 0;
    for (const [key, value] of Object.entries(newSurveyState)) {
      if (value) sum++;
    }

    setFormState({ ...formState, stopbangScore: sum, ...newSurveyState });
    setSurveyCheck(newSurveyState);
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false)
    }
  };

  const isEmptyField = (key, state) => {
    if (state === "formState") {
      return (
        formState[key] === undefined ||
        formState[key] === null ||
        formState[key] === ""
      );
    } else {
      return BMI[key] === undefined || BMI[key] === null || BMI[key] === "";
    }
  };

  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const createSurveyOrder = (e) => {
    e.preventDefault();
    let isValid = true;
    setActive(true)
    const formStateError = { ...formErrorState };
    const BMIStateError = { ...BMIErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key, "formState")) {
        isValid = false;
        formStateError[key] = { error: true };
      }
    }
    for (const [key, _] of Object.entries(BMIErrorState)) {
      if (isEmptyField(key, "BMI")) {
        isValid = false;
        BMIStateError[key] = { error: true };
      }
    }
    if (!DOB) {
      isValid = false;
      setErrorDOB(true);
    }
    if (!isValid) {
      notificationToastError("Please fill all the required fields");
      setFormErrorState(formStateError);
      setBMIErrorState(BMIStateError);
      setActive(false)
      return false;
    }

    const payload = {
      ...formState,
      ...BMI,
      heightFt: BMI.heightFeet,
      heightIn: BMI.heightInches,
      patBMI: BMI.bmi,
      patWeight: BMI.weight,
      interpPhysId: formState.interpPhysId,
    };
    setIsSaveButtonDisabled(true)
    surveyPostData(axios, "saveSurveyOrder", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
          setTimeout(() => {
            navigate(`/dashboard`);
          }, 2000);
        } else {
          notificationToastError(res?.data?.message);
          setActive(false)
        }
      })
      .catch((err) => notificationToastError(err?.message));
  };

  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }

    return age_now;
  };

  const createSurvey = (e) => {
    e.preventDefault();
    let isValid = true;
    const formStateError = { ...formErrorState };
    const BMIStateError = { ...BMIErrorState };
    setActive(true)
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key, "formState")) {
        isValid = false;
        formStateError[key] = { error: true };
      }
    }
    for (const [key, _] of Object.entries(BMIErrorState)) {
      if (isEmptyField(key, "BMI")) {
        isValid = false;
        BMIStateError[key] = { error: true };
      }
    }
    if (!DOB) {
      isValid = false;
      setErrorDOB(true);
    }
    const hasNegativeNumber =
      BMI.heightFeet < 1 || BMI.weight < 1 || BMI.heightInches < 0;
    if (hasNegativeNumber) {
      isValid = false;
      notificationToastError(
        "Height and weight should not be negative numbers"
      );
      setActive(false)
      return false;
    }
    if (!isValid) {
      notificationToastError("Please fill all the required fields");
      setFormErrorState(formStateError);
      setBMIErrorState(BMIStateError);
      setActive(false)
      return false;
    }

    const payload = {
      ...formState,
      ...BMI,
      heightFt: BMI.heightFeet,
      heightIn: BMI.heightInches,
      patBMI: BMI.bmi,
      patWeight: BMI.weight,
      interpPhysId: formState.interpPhysId,
    };
    surveyPostData(axios, "saveSurvey", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
          setTimeout(() => {
            navigate(`/dashboard`);
          }, 2000);
        } else {
          notificationToastError(res?.data?.message);
          setActive(false)
        }
      })
      .catch((err) => notificationToastError(err?.message));
  };

  return (
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      {<SearchPatient selectedPatient={selectedPatient} />}

      {/* {!selectedPatient?.patientId && isSelectPatient && (
        <PatientSearch
          setIsSelectPatient={setIsSelectPatient}
          isSelectPatient={isSelectPatient}
        />
      )}
      {selectedPatient && (
        <SelectedPatients
          selectedPatient={selectedPatient}
          unselectPatient={unselectPatient}
        />
      )}
      */}
      <FileUpload isFileUpload={false} patientId={selectedPatient?.patientId} />

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "20px",
          background:readOnlyBackground
        }}
        noValidate
        autoComplete="off"
      >
        <PatientInfo
          formState={formState}
          updateInput={updateInput}
          DOB={DOB}
          updateDOB={updateDOB}
          errorDOB={errorDOB}
          setErrorDOB={setErrorDOB}
          formErrorState={formErrorState}
          setFormErrorState={setFormErrorState}
          isReadOnly={true}
          handleFocus={handleFocus}
          BMIState={BMI}
        />

        <InsuranceInfo
          formState={formState}
          setFormState={setFormState}
          isReadOnly={true}
          handleFocus={handleFocus}
        />

        
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        
        <Typography
          variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          {" "}
          BMI Calculation
        </Typography>

        <TextField
          id="heightFeet"
          {...getErrorValidationPropertyHieght("heightFeet")}
          label="Height (Feet)"
          type="number"
          value={BMI.heightFeet || ""}
          variant="standard"
          onChange={updateBMIInput}
          required
          error={BMIErrorState?.heightFeet?.error}
          helperText={
            BMIErrorState.heightFeet?.error && !BMI.heightFeet
              ? "required"
              : BMIErrorState.heightFeet?.error &&
                BMI.heightFeet &&
                "height should be positive number"
          }
        />
        <TextField
          id="heightInches"
          {...getErrorValidationProperty("heightFeet")}
          label="Height (Inches)"
          value={BMI.heightInches || ""}
          type="number"
          variant="standard"
          onChange={updateBMIInput}
          required
          error={BMIErrorState?.heightInches?.error}
          helperText={
            BMIErrorState.heightInches?.error && !BMI.heightInches
              ? "required"
              : BMIErrorState.heightInches?.error &&
                BMI.heightInches &&
                "height should be positive number"
          }
        />
        <TextField
          id="weight"
          {...getErrorValidationProperty("heightFeet")}
          label="Weight (lbs)"
          value={BMI.weight || ""}
          type="number"
          variant="standard"
          onChange={updateBMIInput}
          required
          error={BMIErrorState?.weight?.error}
          helperText={
            BMIErrorState.weight?.error && !BMI.weight
              ? "required"
              : BMIErrorState.weight?.error &&
                BMI.weight &&
                "weight should be positive number"
          }
        />

        <TextField
          id="bmi"
          label={`Calculated BMI`}
          value={BMI.bmi === undefined ? "" : BMI.bmi}
          helperText=""
          variant="standard"
          onChange={updateBMIInput}
        />
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          PAP Therapy
        </Typography>

        <FormControl sx={{ m: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                id="usingCpap"
                checked={Boolean(formState.usingCpap)}
                onChange={updateCheckBox}
              />
            }
            label="Are you currently using a CPAP or BiPAP machine while sleeping?"
          />
        </FormControl>
      </Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            paddingLeft: "0.5rem",
            paddingTop: "1rem",
          }}
        >
          Check all that apply
        </Typography>

        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="snoring"
                  checked={Boolean(formState.snoring)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Do you snore?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="tired"
                  checked={Boolean(formState.tired)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Do you often feel Tired, Fatigued, or Sleepy during the daytime?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="choking"
                  checked={Boolean(formState.choking)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Has anyone observed you stop breathing or choking/gasping during your sleep?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="bloodPressure"
                  checked={Boolean(formState.bloodPressure)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Do you have (or are you being treated for) high blood pressure?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="overFifty"
                  checked={Boolean(formState.overFifty)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Are you older than 50 years of age?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="largeNeck"
                  checked={Boolean(formState.largeNeck)}
                  onChange={updateSurveyCheck}
                />
              }
              label='Neck size large? (measured around Adam apple. 17" shirt collar for males, or 16" for females)'
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="genderMale"
                  checked={Boolean(formState.genderMale)}
                  onChange={updateSurveyCheck}
                />
              }
              label="Is your gender male?"
            />
          </FormControl>
        </div>
        <div>
          <FormControl sx={{ m: 1 }}>
            <FormControlLabel
              control={<Checkbox id="patientBMI" />}
              label="Patient's BMI > 25?"
              checked={Boolean(surveyCheck.patientBMI)}
            />
          </FormControl>
        </div>
        <div>
          <TextField
            id="stopbangScore"
            label="STOP-BANG score"
            value={
              formState.stopbangScore === undefined
                ? ""
                : formState.stopbangScore
            }
            helperText=""
            variant="standard"
            onChange={updateInput}
          />
        </div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "98%" },
            "& .MuiFormControl-root": { flexGrow: 1 },
            // border: "1px solid rgba(0,0,0, 0.12)",
            borderRadius: "4px",
            margin: "10px 0px",
            display: "flex",
          }}
        >
          <PhysicianPulldown
            formState={formState}
            updateInput={updateInput}
            attributeName={"interpPhysId"}
            formErrorState={formErrorState}
            validateEmptyField={validateEmptyField}
          />
        </Box>
      </Box>

      {returnButton(formState, createSurveyOrder, createSurvey,isSaveButtonDisabled,active)}

      <div style={{ paddingBottom: isMobile ? "4rem" : "2rem"}}>
        <span style={{display: "inline-block", marginBottom: isMobile ? '5px' : "0px"}}>By filling out the form, you are agreeing with our</span>
        <CustomizedDialogs />
      </div>

      <ToastContainer />
    </Container>
  );
}

export default NewSurvey;
